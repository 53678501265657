import React from "react"
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Container,
  Grid,
  Card,
} from "@material-ui/core"
import officeImage from "../assets/office.png"

const OurServices = () => {
  return (
    <div style={{}}>
      <Container maxWidth="lg" style={{ marginTop: 24, marginBottom: 24 }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h3" gutterBottom>
                Qué Hacemos
              </Typography>
              <Typography variant="h4" style={{ fontSize: 14 }}>
                Planes de Negocio:
              </Typography>
              <Card
                style={{
                  marginTop: 12,
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                }}
              >
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Planes Estratégicos"
                      secondary="Desarrollamos planes de negocios para mejorar la competitividad y los resultados de las unidades económicas, con índices medibles en los tiempos acordados."
                    />
                  </ListItem>
                </List>
              </Card>
              <Card
                style={{
                  marginTop: 12,
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                }}
              >
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Protección Corporativa"
                      secondary="Nuestro blindaje corporativo está orientado a fortalecer las mallas societarias para que las empresas tengan flexibilidad en escenarios complejos."
                    />
                  </ListItem>
                </List>
              </Card>

              <Typography variant="h4" style={{ fontSize: 14, marginTop: 12 }}>
                Reestructuración Corporativa:
              </Typography>
              <Card
                style={{
                  marginTop: 12,
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                }}
              >
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Apalancamiento e Inversiones"
                      secondary="Nuestra red de fondos de inversión privados certificados por la CMF, nos proporciona soluciones financieras estructuradas y diagnósticos ágiles."
                    />
                  </ListItem>
                </List>
              </Card>
              <Card
                style={{
                  marginTop: 12,
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                }}
              >
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Reestructuración de Pasivos"
                      secondary="Gestionamos la reestructuración de pasivos a través de innovadoras soluciones para empresas con o sin Dicom."
                    />
                  </ListItem>
                </List>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <img
              src={officeImage}
              alt="Office"
              style={{
                width: "100%",
                borderRadius: 12,
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              }}
            />
            <Grid container justify="center">
              <Typography variant="caption" style={{ textAlign: "center" }}>
                Nuestra sede ubicada en Los Abedules 3085
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default OurServices

import React, { useEffect, useRef } from "react"
import Hero from "../components/Hero"
import "./styles.css"
import { NavbarLayout } from "../layouts"
import OurServices from "../components/OurServices"
import OurTeam from "../components/OurTeam"
import WhyProAdvise from "../components/WhyProadvise"

const WrappedApp = () => {
  return (
    <NavbarLayout>
      <App />
    </NavbarLayout>
  )
}
const App = () => {
  const pricingRef = useRef()

  useEffect(() => {
    const doNotShow = localStorage.getItem("doNotShowAgainToggle")
    if (!doNotShow) {
    }
  }, [])

  useEffect(() => {
    if (window.location.hash === "#price") {
      pricingRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  return (
    <div style={{ position: "relative" }}>
      <div style={{ position: "relative" }}>
        <Hero />
        <WhyProAdvise />
        <OurServices />
        <OurTeam />
      </div>
    </div>
  )
}

export default WrappedApp

import { ThemeProvider, Card } from "@material-ui/core"
import { AuthProvider } from "../context/auth"
import theme from "../context/theme"
import AuthorizedPageWrapper from "../context/AuthorizedPageWrapper"
import React from "react"
import Helmet from "react-helmet"
import Navbar from "../components/Navbar"
import { CssBaseline } from "@material-ui/core"
import "./styles.css"
import Footer from "../components/Footer"
import { SubscriptionsProvider } from "../context/SubscriptionsContext"
import ChatIcon from "@material-ui/icons/Chat" // Import the chat icon from material-ui
import Fab from "@material-ui/core/Fab" // Import the floating action button from material-ui
import { useState } from "react" // Import useState from react
import Chat from "../components/Chat"
import { FaBolt } from "react-icons/fa6"

export const BasicLayout = ({ children }) => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AuthProvider>
  )
}

export const AuthorizedLayout = ({ children }) => {
  return (
    <BasicLayout>
      <AuthorizedPageWrapper>{children}</AuthorizedPageWrapper>
    </BasicLayout>
  )
}

export const NavbarLayout = ({ children }) => {
  const Content = () => {
    const [openChat, setOpenChat] = useState(false) // State to handle the chat window open/close

    const handleChatOpen = () => {
      setOpenChat(!openChat) // Function to open the chat window
    }

    const handleChatClose = () => {
      setOpenChat(false) // Function to close the chat window
    }

    return (
      <div
        style={{
          width: "100vw",
          minHeight: "100vh",
          padding: 0,
          position: "relative", // Add relative position to allow absolute positioning inside
        }}
      >
        <CssBaseline />
        <Helmet>
          <title>Proadvise.cl - Negotiate and Advising</title>
          <meta
            name="description"
            content="Proadvise.cl - Negotiate and Advising"
          />
        </Helmet>

        <div>
          <div>{children}</div>
          <Footer />
        </div>

        {openChat && (
          <Card
            elevation={5}
            style={{
              position: "fixed", // Changed position to 'fixed'
              right: 50,
              bottom: 90,
              width: 400,
              height: 700,

              backgroundColor: "white",
              zIndex: 1000,
            }}
          >
            <Chat />
          </Card>
        )}
      </div>
    )
  }

  return (
    <AuthProvider>
      <SubscriptionsProvider>
        <ThemeProvider theme={theme}>
          <Content />
        </ThemeProvider>
      </SubscriptionsProvider>
    </AuthProvider>
  )
}

import React from "react"
import {
  Grid,
  Typography,
  Hidden,
  makeStyles,
  useTheme,
  Container,
  useMediaQuery,
  Button,
} from "@material-ui/core"
import heroImage from "../assets/slider-1.jpeg" // Adjust the import path as necessary
import { CheckCircle } from "@material-ui/icons"
import Navbar from "./Navbar"

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50vh",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    backgroundImage: `url(${heroImage})`,
    backgroundSize: "cover", // Cover the entire container
    backgroundPosition: "center", // Center the background image
    [theme.breakpoints.down("md")]: {
      minHeight: "70vh",
    },
  },
  title: {
    marginBottom: 12,
    textAlign: "center",
    fontWeight: 300,
    fontSize: "44px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: 29,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3.6em",

      marginBottom: "0.5em",
    },
  },
  punchline: {
    fontWeight: 500,
    fontSize: "24px",
    marginBottom: 12,
    lineHeight: 1.2,
    width: "100%",
    textAlign: "center",
    fontFamily: "Gilroy Medium",
    [theme.breakpoints.down("md")]: {
      fontSize: 17,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "1em",
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: "1em",
      fontSize: "2.1em",
    },
  },

  hero: {
    zIndex: 101,
    alignSelf: "center",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
    },
    [theme.breakpoints.up("lg")]: {
      height: "60vh",
    },
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      minHeight: "40vh",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
      margin: "72px auto",
      height: "40vh",
    },
  },
  buttonLabel: {
    fontWeight: 500,
    textTransform: "uppercase",
    fontFamily: "Gilroy Bold",
  },
  textContainer: {
    padding: 12,
    marginTop: "48px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Alinea los elementos hijos en el centro horizontalmente
    justifyContent: "center", // Alinea los elementos hijos en el centro verticalmente
    [theme.breakpoints.up("md")]: {
      height: "70vh",
    },
    [theme.breakpoints.up("xl")]: {
      padding: 0,
    },
  },
  button: {
    fontWeight: 900,
    padding: "9px 24px 9px 24px",
    color: "#fff",
    borderRadius: "15px",
  },
}))

const Hero = () => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      <div
        style={{
          top: 0,
          left: 0,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
          color: "white",
        }}
      >
        <Navbar />
        <Grid container className={classes.root}>
          <Grid container justify="center">
            <Grid item xs={12} md={10} className={classes.textContainer}>
              <Typography variant="h1" component="h1" className={classes.title}>
                Asesoría Corporativa para la alta dirección de empresas
              </Typography>

              <Typography
                variant="h2"
                component="h2"
                className={classes.punchline}
              ></Typography>
              <Grid container justify="center" spacing={2}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    window.open("https://wa.me/56967457408", "_blank")
                  }}
                >
                  <Typography className={classes.buttonLabel}>
                    Contáctanos
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Hero

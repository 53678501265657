import React, { useState, useEffect, useRef } from "react"
import {
  Paper,
  Snackbar,
  Grid,
  Typography,
  Avatar,
  List,
  ListItem,
  TextField,
  InputAdornment,
  IconButton,
  ListItemText,
} from "@material-ui/core"
import SendIcon from "@material-ui/icons/Send"
import { useStyles } from "../common/articleBenderStyles"
import "./styles.css"
import ChatMessage from "./ChatMessage"
import Alert from "@material-ui/lab/Alert"
import FeedbackComponent from ".//FeedbackComponent"
import useChat from "../hooks/useChat"
import useFeedback from "../hooks/useFeedback"
import Mage from "../assets/Mage.png"

const sampleQuestions = [
  "¿Qué eres capaz de hacer?",
  "¿Cómo puedo integrar IA en mi negocio?",
  "¿Qué tipo de software puedo obtener?",
]

const App = () => {
  const classes = useStyles()
  const paperRef = useRef()
  const endRef = useRef()
  const {
    input,
    setInput,
    loading,
    error,
    setError,
    conversation,
    conversationId,
    handleSubmit,
    isWriting,
    errorMsg,
  } = useChat(endRef)

  const { createFeedback, updateFeedback, feedbackId } = useFeedback(
    setError,
    conversationId
  )
  const [showClipboardNotification, setShowClipboardNotification] =
    useState(false)
  const [isFeedbackModalOpen, setShowFeedbackModal] = useState(false)
  const handleInputChange = (event) => {
    setInput(event.target.value)
  }

  useEffect(() => {
    endRef?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" })
  }, [conversation])

  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setError(false)
    setShowClipboardNotification(false)
  }

  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false)
  }

  const handleOpenPositiveFeedbackModal = () => {
    setShowFeedbackModal(true)
    if (!feedbackId) {
      createFeedback(true)
    }
  }

  const handleOpenNegativeFeedbackModal = () => {
    setShowFeedbackModal(true)
    if (!feedbackId) {
      createFeedback(false)
    }
  }

  const handleSendFeedback = (feedbackText) => {
    updateFeedback(feedbackText)
    handleCloseFeedbackModal(false)
  }

  return (
    <div style={{ height: "90vh" }}>
      <Grid
        container
        style={{ padding: 0, margin: 0, height: "40vh" }}
        justify="center"
      >
        <Grid item xs={12}>
          <Paper ref={paperRef} elevation={0} className={classes.paper}>
            <div>
              {!conversation.memory.length && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                  style={{ background: "#3858ff", padding: 24 }}
                >
                  <Grid item xs={9}>
                    <Typography
                      style={{ fontWeight: 900, color: "#fff", fontSize: 40 }}
                    >
                      Hola👋
                      <br /> ¡Soy la IA de Alchtec!
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {!conversation.memory.length && (
                <div style={{ padding: 24, marginTop: -36 }}>
                  <Paper style={{ padding: 24 }}>
                    <Grid container style={{ display: "flex" }}>
                      <Grid item xs={2} style={{ marginTop: 12 }}>
                        <Avatar sizes="50px" src={Mage} />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography>
                          Y estoy aquí para ayudarte con tus preguntas!
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              )}
              <div style={{ padding: 24, marginTop: -36 }}>
                <Paper>
                  {!conversation.memory.length && (
                    <Grid
                      container
                      style={{ width: "100%", margin: "24px auto" }}
                    >
                      {!conversation.memory.length && (
                        <List>
                          {sampleQuestions.map((question) => (
                            <ListItem
                              button
                              onClick={() => handleSubmit(question)}
                            >
                              <ListItemText primary={question} />
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </Grid>
                  )}
                </Paper>
              </div>
              {conversation.memory.length > 0 && (
                <Grid
                  container
                  style={{ overflowY: "auto", height: "55vh", padding: 24 }}
                >
                  {conversation?.memory?.map(
                    (
                      { text, source, senderType, systemMessage, error },
                      index
                    ) => (
                      <Grid container key={text}>
                        <ChatMessage
                          message={text}
                          paperRef={endRef}
                          sender={source === "ai" ? "Asistente" : "Tú"}
                          senderType={
                            senderType === "loading" ? senderType : source
                          }
                          systemMessage={systemMessage}
                          isInitialMessage={index === 0}
                          isWritingMessage={isWriting}
                          setShowClipboardNotification={
                            setShowClipboardNotification
                          }
                          handleOpenPositiveFeedbackModal={
                            handleOpenPositiveFeedbackModal
                          }
                          handleOpenNegativeFeedbackModal={
                            handleOpenNegativeFeedbackModal
                          }
                          error={error}
                        />
                      </Grid>
                    )
                  )}
                  <div ref={endRef} />
                </Grid>
              )}

              <Grid
                container
                justify="center"
                style={{ paddingLeft: 24, paddingRight: 24 }}
              >
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={"Escribe tu consulta"}
                    variant="outlined"
                    value={input}
                    onChange={handleInputChange}
                    multiline
                    rows={1}
                    disabled={loading || isWriting}
                    className={classes.textField}
                    InputProps={{
                      onKeyDown: (event) => {
                        if (event.key === "Enter" && !event.shiftKey) {
                          event.preventDefault() // To prevent a new line from being added
                          handleSubmit()
                          endRef?.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                          })
                        }
                      },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ color: "#fff" }}
                        >
                          <IconButton
                            style={{ color: "#fff" }}
                            onClick={() => {
                              handleSubmit()
                              endRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                              })
                            }}
                            disabled={loading || isWriting}
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Snackbar
        open={showClipboardNotification}
        autoHideDuration={2000}
        onClose={handleCloseNotification}
      >
        <Alert> Mensaje copiado al cortapapeles.</Alert>
      </Snackbar>

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
      <FeedbackComponent
        open={isFeedbackModalOpen}
        handleClose={handleCloseFeedbackModal}
        handleSendFeedback={handleSendFeedback}
      />
    </div>
  )
}

export default App

import { PASSWORD_REGEX } from "./consts"

export const callAnalyticsEvent = (eventName, options = {}) =>
  window.gtag !== undefined && window.gtag("event", eventName, options)

export const validateEmail = (email) => {
  return /^\S+@\S+\.\S+$/.test(email)
}

export const currencyFormatter = (
  amount,
  decimalCount = 0,
  decimal = ",",
  thousands = ".",
  symbol = "$"
) => {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? "-" : ""

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString()
    const j = i.length > 3 ? i.length % 3 : 0

    return `${symbol} ${negativeSign}${j ? i.substr(0, j) + thousands : ""}${i
      .substr(j)
      .replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}${
      decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : ""
    }`
  } catch (e) {
    console.log(e)
    return false
  }
}

export const currencyDecode = (amount) => {
  try {
    // Clear data
    amount = amount.replace("$", "")
    amount = amount.replace(/\./g, "")
    return amount
  } catch (e) {
    console.log(e)
  }
}

export const validatePassword = (password) => {
  return PASSWORD_REGEX.test(password)
}

import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"

import TextField from "@material-ui/core/TextField"

const FeedbackComponent = ({ open, handleClose, handleSendFeedback }) => {
  const [feedback, setFeedback] = useState("")

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Tu opinion nos importa</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Si lo deseas puedes escribir una opinion:
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="feedback"
          type="text"
          fullWidth
          value={feedback}
          onChange={handleFeedbackChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button
          disabled={!feedback}
          onClick={(evt) => handleSendFeedback(feedback)}
          color="primary"
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FeedbackComponent

export const ENV = process.env.ENV

const URL_ENVS = {
  staging: "https://api.Alchtec.com",
  production: "https://api.Alchtec.com",
}

export const URL_BASE = URL_ENVS[ENV] || "http://localhost:3001"

export const BACKEND_AUTH_ROUTES = {
  register: URL_BASE + "/auth/register",
  login: URL_BASE + "/auth/login",
  verify: URL_BASE + "/auth/verify-email",
  auth: URL_BASE + "/auth",
  resetPassword: URL_BASE + "/auth/recover-password",
  changePassword: URL_BASE + "/auth/change-password",
}

export const BACKEND_TOOL_ROUTES = {
  s3Url: URL_BASE + "/s3-url",
  profile: URL_BASE + "/profile",
  product: URL_BASE + "/product",
}

export const REGISTER_ERROR_MESSAGES = {
  0: "Ha ocurrido un error",
}

export const CHAT_ERROR_MESSAGES = {
  7000: "El archivo supera el limite de palabras.",
}

export const LOGIN_ERROR_MESSAGES = {
  0: "Ha ocurrido un error inesperado. ",
  401: "La contraseña ingresada no es valida.",
  1000: "Invalid or expired token",
  2: "Las contraseñas no coinciden.",
  3: "La contraseña debe ser al menos de 8 carácteres.",
  4: "La contraseña ha sido cambiada con éxito.",
  5: "Debes verificar tu email para iniciar sesión.",
  1100: "Esté email ya esta siendo utilizado.",
  1101: "La contraseña es muy corta (8 caracteres min.)",
  1102: "Invalid or expired token",
  1103: "La contraseña ingresada no es valida.",
}

export const PASSWORD_REGEX = /(?=.*[A-Z])(?=.*[0-9])(?=.{10,})/

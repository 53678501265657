// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react"
import axios from "axios"
import { URL_BASE } from "../utils/consts"
import { callAnalyticsEvent } from "../utils"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    checkAuthentication()
  }, [])

  const checkAuthentication = async (isGoogleAuth) => {
    const token = localStorage.getItem("token")
    if (!token) {
      setLoading(false)
      return
    }
    try {
      const response = await axios.get(URL_BASE + "/auth/is-authenticated", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.status === 200 && response.data.isAuthenticated) {
        setIsAuthenticated(true)
        setUser(response.data.user)
        if (isGoogleAuth) {
          callAnalyticsEvent("login.google.success")
          localStorage.setItem("token", response.data.refreshToken)
        }
      }
    } catch (error) {
      localStorage.removeItem("token")

      if (isGoogleAuth) {
        callAnalyticsEvent("login.google.error")
      }

      if (error.response && error.response.status === 401) {
        setIsAuthenticated(false)
      } else {
        setError(error)
      }
    } finally {
      setLoading(false)
    }
  }

  const logout = async () => {
    // Here I'm assuming you don't need to call the /auth/logout endpoint anymore
    // as you can just remove the token to "logout" the user
    localStorage.removeItem("token")
    setUser()
    setIsAuthenticated(false)
  }

  const login = async (email, password, googleResponse) => {
    try {
      const response =
        googleResponse ||
        (await axios.post(URL_BASE + "/auth/login", {
          email,
          password,
        }))
      localStorage.setItem("token", response.data.token)
      setIsAuthenticated(true)
      setUser(response.data.user)
    } catch (error) {
      setError(error)
      throw error
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading,
        checkAuthentication,
        user,
        error,
        logout,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }

  return context
}

export default useAuth

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  appContainer: {
    paddingTop: theme.spacing(4),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },

  paper: {
    overflowY: "auto",

    overflowY: "auto",

    [theme.breakpoints.up("md")]: {},
  },
  fileUploadContainer: {
    [theme.breakpoints.up("md")]: {
      padding: 12,
    },
  },
  progress: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))

const useMdStyles = makeStyles((theme) => ({
  markdown: {
    "& h1": {
      ...theme.typography.h1,
    },
    "& h2": {
      ...theme.typography.h2,
    },
    "& h3": {
      ...theme.typography.h3,
    },
    "& h4": {
      ...theme.typography.h4,
    },
    "& h5": {
      ...theme.typography.h5,
    },
    "& h6": {
      ...theme.typography.h6,
    },
    "& p": {
      ...theme.typography.body1,
    },
  },
}))

export { useMdStyles, useStyles }

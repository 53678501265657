import { URL_BASE } from "../utils/consts"
import { useState } from "react"

const useFeedback = (setError = () => {}, conversationId) => {
  const [feedbackId, setFeedbackId] = useState(null)
  const createFeedback = async (isPositive) => {
    try {
      const token = localStorage.getItem("token")
      const response = await fetch(URL_BASE + "/feedback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          isPositive,
          conversationId: conversationId,
        }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.error)
      }

      setFeedbackId(data.feedback._id)
    } catch (error) {
      console.log(error)
      setError(error.message)
    }
  }

  const updateFeedback = async (feedbackText) => {
    if (!feedbackId || !feedbackText) return

    try {
      const token = localStorage.getItem("token")
      const response = await fetch(URL_BASE + `/feedback/${feedbackId}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ feedbackText }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.error)
      }
    } catch (error) {
      setError(error.message)
    }
  }
  return { createFeedback, updateFeedback, feedbackId }
}

export default useFeedback

// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from "react"
import axios from "axios"
import { URL_BASE } from "../utils/consts"
import { useAuth } from "./auth.js"

export const SubscriptionsContext = createContext()

export const SubscriptionsProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [activeSubscriptions, setActiveSubscriptions] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    getSubscriptions()
  }, [])

  const getSubscriptions = async () => {
    const token = localStorage.getItem("token")
    if (!token) {
      setLoading(false)
      return
    }
    try {
      const response = await axios.get(URL_BASE + "/subscriptions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.status === 200 && response.data.isAuthenticated) {
        setActiveSubscriptions(response.data.user)
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <SubscriptionsContext.Provider
      value={{
        loading,
        activeSubscriptions,
        error,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  )
}

const useSubscriptions = () => {
  const context = useContext(SubscriptionsContext)

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }

  return context
}

export default useSubscriptions

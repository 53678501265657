import React from "react"
import {
  Card,
  CardContent,
  Typography,
  Link,
  Grid,
  Avatar,
  Container,
} from "@material-ui/core"
import franciscoLeyton from "../assets/francisco-leyton-2.jpg"
import juanDavid from "../assets/juan-david-2.jpg"
import ricardoGarrido from "../assets/ricardo-garrido.jpg"

const teamMembers = [
  {
    name: "Francisco Javier Leyton",
    role: "Director Ejecutivo",
    description:
      "Un líder visionario con una amplia experiencia en impulsar el crecimiento y la innovación en soluciones empresariales.",
    linkedin: "https://www.linkedin.com",
    imageUrl: franciscoLeyton, // Añade la URL de la imagen aquí
  },
  {
    name: "Ricardo Garrido",
    role: "Director Legal",
    description:
      "Experto en navegar las complejidades del derecho corporativo.",
    linkedin: "https://www.linkedin.com",
    imageUrl: ricardoGarrido, // Añade la URL de la imagen aquí
  },
  {
    name: "Juan David Villalobos",
    role: "Operaciones",
    description:
      "Especialista en optimizar operaciones y garantizar procesos comerciales fluidos.",
    linkedin: "https://www.linkedin.com",
    imageUrl: juanDavid, // Añade la URL de la imagen aquí
  },
]

const OurTeam = () => {
  return (
    <Container
      maxWidth="lg"
      style={{
        textAlign: "center",
        marginTop: 48,
        marginBottom: 24,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Nuestro Equipo
      </Typography>
      <Typography paragraph style={{ textAlign: "center" }}>
        En ProAdvise, nos especializamos en proporcionar servicios estratégicos
        de asesoría corporativa dirigidos a directivos y gerencias. Nuestro
        equipo multidisciplinario, liderado por nuestros directores
        experimentados, reúne una diversa gama de habilidades para abordar los
        desafíos únicos que enfrentan las empresas hoy en día, carrera y
        resultados demostrables.
      </Typography>

      <Grid container spacing={3} justify="center" style={{ marginTop: 48 }}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              style={{
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                minHeight: 400,
              }}
            >
              <CardContent style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 12,
                  }}
                >
                  {member.imageUrl ? (
                    <img
                      src={member.imageUrl}
                      alt={member.name}
                      style={{
                        width: "130px",
                        height: "130px",
                        marginBottom: 24,
                        objectFit: "cover",
                        objectPosition: "top", // Ajuste para mover la imagen hacia abajo
                        borderRadius: "50%", // Para mantener la forma circular
                      }}
                    />
                  ) : (
                    <Avatar
                      src={member.imageUrl}
                      alt={member.name}
                      style={{
                        width: "130px",
                        height: "130px",
                        marginBottom: 24,
                        objectPosition: "top", // Ajuste para mover la imagen hacia abajo
                      }}
                    />
                  )}
                </div>
                <Typography variant="h6">{member.name}</Typography>
                <Typography color="textSecondary">{member.role}</Typography>
                <Typography
                  variant="body2"
                  paragraph
                  style={{ marginTop: 16, minHeight: 100 }}
                >
                  {member.description}
                </Typography>
                <Link href={member.linkedin} target="_blank">
                  LinkedIn
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default OurTeam

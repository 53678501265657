import React, { useState } from "react"
import {
  Button,
  Container,
  Modal,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core"
import { Link } from "@material-ui/core"

const WhyProAdvise = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [key, setKey] = useState("")
  const [accessGranted, setAccessGranted] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setAccessGranted(false) // Reset access on close
  }

  const handleKeyChange = (event) => {
    setKey(event.target.value)
  }

  const validateKey = () => {
    if (key === "clave") {
      // Reemplazar 'claveCorrecta' con la clave real
      setAccessGranted(true)
    } else {
      alert("Clave incorrecta")
    }
  }

  return (
    <div
      style={{
        background: "#fff",
        paddingTop: 48,
        paddingBottom: 48,
      }}
    >
      <Container maxWidth="lg">
        <Grid container justify="space-between" spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: 24 }}>
              Por Qué Elegir ProAdvise
            </Typography>
            <Typography paragraph>
              Con un alcance nacional y un historial comprobado, ProAdvise
              ofrece resultados que importan. Dentro de la última década, hemos
              demostrado consistentemente nuestra capacidad para impulsar
              mejoras significativas para nuestros clientes.
            </Typography>
            <Typography paragraph>
              <b>Enfoque Orientado a Resultados:</b> Nuestros honorarios son en
              función de los objetivos cumplidos.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: 24 }}>
              Historias de Éxito.
            </Typography>
            <Typography paragraph>
              Acceda a nuestro portal privado para ver estudios de casos
              detallados y los resultados impactantes que hemos logrado en los
              últimos cinco años.
            </Typography>
            <Button
              style={{ marginTop: 12 }}
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
            >
              <Typography
                style={{
                  fontWeight: 500,
                  textTransform: "uppercase",
                  fontFamily: "Gilroy Bold",
                  color: "#fff",
                }}
              >
                Acceder con clave
              </Typography>
            </Button>

            <Typography style={{ marginTop: 12 }}>
              ¿No tienes acceso?{" "}
              <Link href="mailto:contacto@proadvise.com">Contáctanos</Link>
            </Typography>
          </Grid>
        </Grid>

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {accessGranted ? (
              <Typography>
                Información sobre los casos de éxito de negocio
              </Typography>
            ) : (
              <>
                <TextField
                  label="Ingrese la clave"
                  type="password"
                  value={key}
                  onChange={handleKeyChange}
                />
                <Button onClick={validateKey}>Validar</Button>
              </>
            )}
          </div>
        </Modal>
      </Container>
    </div>
  )
}

export default WhyProAdvise

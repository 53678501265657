import React from "react"
import { IconButton, Grid } from "@material-ui/core"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import { makeStyles } from "@material-ui/core/styles"
import { ThumbDown, ThumbUp } from "@material-ui/icons"

// Define the styles for the button
const useStyles = makeStyles(() => ({
  iconButton: {
    borderRadius: "50%",
    padding: "5px",
  },
}))

const ClipboardIconButton = ({
  textToCopy,
  senderType,
  handleOpenPositiveFeedbackModal,
  handleOpenNegativeFeedbackModal,
  setShowClipboardNotification,
}) => {
  const classes = useStyles()

  const handleCopy = async () => {
    try {
      // Copy the text to clipboard
      await navigator.clipboard.writeText(textToCopy)
      setShowClipboardNotification(true)
    } catch (err) {
      console.error("Failed to copy text: ", err)
    }
  }

  // Choose the icon based on the senderType
  const icon = senderType === "ai" && <FileCopyIcon />
  const feedbackButtons = senderType === "ai" && (
    <>
      <IconButton
        style={{ color: "#989898" }}
        onClick={handleOpenNegativeFeedbackModal}
        className={classes.iconButton}
      >
        <ThumbDown />
      </IconButton>
      <IconButton
        style={{ color: "#989898" }}
        onClick={handleOpenPositiveFeedbackModal}
        className={classes.iconButton}
      >
        <ThumbUp />
      </IconButton>
    </>
  )
  return (
    <div>
      <Grid container style={{ color: "#989898" }}>
        <IconButton
          style={{ color: "#989898" }}
          onClick={handleCopy}
          className={classes.iconButton}
        >
          {icon}
        </IconButton>
        {feedbackButtons}
      </Grid>
    </div>
  )
}

export default ClipboardIconButton

import { useState } from "react"
import { URL_BASE } from "../utils/consts"
import { callAnalyticsEvent } from "../utils"

const useChat = (endRef) => {
  const [input, setInput] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isWriting, setIsWriting] = useState(false)
  const [conversation, setConversation] = useState({ memory: [] })
  const [conversationId, setConversationId] = useState(null)
  const errorMsg = "Ha ocurrido un error inesperado."
  const addInitialConversationMsg = (baseQuestion) => {
    const newConversation = conversation
    const newMemory = [...conversation.memory]

    newMemory.push({
      source: "user",
      text: baseQuestion || input,
    })

    newMemory.push({
      source: "system",
      text: "",
      senderType: "loading",
    })
    newConversation.memory = newMemory
    setConversation({ memory: newMemory })
  }
  const updateStreamConversation = (chunks) => {
    const newConversation = { ...conversation } // This creates a copy of conversation
    const newMemory = [...newConversation.memory] // This creates a copy of memory
    newMemory[newMemory.length - 1] = {
      source: "ai",
      text: chunks,
      senderType: "assistant",
    }
    newConversation.memory = newMemory
    setConversation(newConversation) // You can directly set the newConversation
  }

  const processStream = async (response) => {
    const reader = response.body.getReader()
    let chunks = ""
    let readTimeout
    while (true) {
      const { done, value } = await reader.read()
      const chunk = new TextDecoder("utf-8").decode(value)
      if (done) {
        setIsWriting(false)
        break
      }
      clearTimeout(readTimeout)

      if (chunk.includes("conversationId:")) {
        const conversationId = chunk.split(":")
        console.log("conversationid", conversationId)
        setConversationId(conversationId[1])
      } else {
        chunks += chunk
        updateStreamConversation(chunks)
        endRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" })
      }
    }
  }

  const handleSubmit = async (baseQuestion) => {
    const token = localStorage.getItem("token")
    addInitialConversationMsg(baseQuestion)
    setLoading(true)
    setIsWriting(true)

    try {
      setInput("")

      const response = await fetch(URL_BASE + "/gpt/agent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          input: baseQuestion || input,

          conversationId: conversationId,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      callAnalyticsEvent("sendChatMessage.success")
      await processStream(response)
    } catch (error) {
      console.log(error)
      callAnalyticsEvent("sendChatMessage.error")
      setError(true)
    } finally {
      setIsWriting(false)
      setLoading(false)
    }
  }

  return {
    input,
    setInput,
    loading,
    error,
    conversation,
    conversationId,
    setConversationId,
    handleSubmit,
    isWriting,
    errorMsg,
    setError,
  }
}

export default useChat

import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Container,
  Typography,
  Link,
  IconButton,
  Grid,
} from "@material-ui/core"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import logo from "../assets/logo-proadvise-2.png" // Adjust the import path as necessary
import { Directions, Email } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing(6),
    background: "#FCFCFC",
  },
  icon: {
    marginRight: "6px",
  },
  logo: {
    cursor: "pointer",
    width: 210,
  },
}))

export default function Footer() {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container justify="center" style={{ marginBottom: "12px" }}>
          <Grid container justify="center">
            <Grid
              item
              xs={12}
              md={3}
              lg={2}
              style={{ marginRight: "24px", marginTop: 18 }}
            >
              <img src={logo} alt="Alchtec.com" className={classes.logo} />
            </Grid>

            <Grid container item xs={12} md={5} lg={3}>
              <Grid item xs={12} style={{ marginRight: 12 }}>
                <Grid container alignItems="center">
                  <WhatsAppIcon color="primary" className={classes.icon} />
                  <Link color="inherit" href="https://wa.me/56967457408">
                    +56 9 6745 7408
                  </Link>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginRight: 12 }}>
                <Grid container alignItems="center">
                  <a
                    href="mailto:contacto@proadvise.cl"
                    className={classes.icon}
                  >
                    <Email color="primary" />
                  </a>
                  <Link color="inherit" href="mailto:contacto@proadvise.cl">
                    contacto@proadvise.cl
                  </Link>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginRight: 12 }}>
                <Grid container alignItems="center">
                  <Directions color="primary" className={classes.icon} />
                  <Link
                    color="inherit"
                    href="https://maps.app.goo.gl/BLQSmqsYfVuvFAz4A"
                  >
                    Los Abedules 3085, Vitacura - Santiago
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          style={{ marginTop: 24, marginBottom: 24 }}
          color="inherit"
          align="center"
        >
          Copyright© 2024 PROADVISE, Negotiate And Advising. Todos los derechos
          reservados.
        </Typography>
      </Container>
    </footer>
  )
}

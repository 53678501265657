import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  CircularProgress,
} from "@material-ui/core"
import { deepPurple, deepOrange } from "@material-ui/core/colors"
import { ErrorOutlineRounded } from "@material-ui/icons"
import ClipboardIconButton from "./ClipboardIconButton"

const useStyles = makeStyles((theme) => ({
  messageContainer: {
    display: "flex",
    padding: 0,
    justifyContent: (props) =>
      props.senderType === "user" ? "flex-start" : "flex-end",
    alignItems: "center",
    width: "100%",
  },
  card: {
    display: "flex",
    padding: 6,
    paddingLeft: 6,
    maxWidth: "100%",
    borderRadius: 12,
    alignItems: "center",
    background: "inherit",
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      minWidth: 240,
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 340,
      maxWidth: "100%",
    },
  },
  avatar: {
    backgroundColor: (props) =>
      props.senderType === "user" ? deepPurple[500] : deepOrange[500],
    marginRight: (props) =>
      props.senderType === "user" ? theme.spacing(1) : 0,
    marginLeft: (props) => (props.senderType === "ai" ? theme.spacing(1) : 0),
  },
  content: {
    flex: 1,
  },
  message: {
    fontSize: "14px",
    whiteSpace: "pre-wrap",
    textAlign: "justify",

    [theme.breakpoints.down("md")]: {
      fontSize: 17,
    },
    [theme.breakpoints.up("md")]: {
      padding: 5,
      paddingLeft: 0,
    },
  },
}))

const ChatMessage = ({
  message,
  sender,
  senderType,
  systemMessage,
  error,
  isInitialMessage,
  isWritingMessage,
  setShowClipboardNotification,
  handleOpenPositiveFeedbackModal,
  handleOpenNegativeFeedbackModal,
}) => {
  const classes = useStyles({ senderType })

  // If senderType is loading, render a loading state
  if (senderType === "loading") {
    return (
      <Grid item xs={12} className={classes.messageContainer} key={message}>
        <Card elevation={0} className={classes.card}>
          <CardContent className={classes.content}>
            <Grid container>
              <Grid item xs={2}>
                {!error ? <CircularProgress /> : <ErrorOutlineRounded />}
              </Grid>
              <Grid item xs={10}>
                <Typography>{systemMessage}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  // Default message rendering
  return (
    <Grid className={classes.messageContainer} key={message}>
      <Card elevation={2} className={classes.card}>
        <CardContent className={classes.content}>
          <Grid container justify="space-between"></Grid>
          <Typography className={classes.message} variant="body2" component="p">
            {message}
          </Typography>
          <Grid container justify="flex-end">
            {!isInitialMessage && !isWritingMessage && (
              <ClipboardIconButton
                setShowClipboardNotification={setShowClipboardNotification}
                senderType={senderType}
                textToCopy={message}
                handleOpenPositiveFeedbackModal={
                  handleOpenPositiveFeedbackModal
                }
                handleOpenNegativeFeedbackModal={
                  handleOpenNegativeFeedbackModal
                }
              />
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ChatMessage

// navbarStyles.js
import { makeStyles } from "@material-ui/core/styles"

const useNavbarStyles = makeStyles((theme) => ({
  navbarContainer: {
    height: 57,
    position: "relative",
    padding: 12,
    paddingBottom: 6,
    paddingLeft: 6,
    paddingRight: 6,
    color: "#fff",
    background: "#004785",
  },
  buttonLabel: {
    fontWeight: 500,
    textTransform: "uppercase",
    fontFamily: "Gilroy Bold",
    color: "#fff",
  },
  logo: {
    cursor: "pointer",
    width: 160,
  },

  zIndex: {
    zIndex: 200,
  },
  item: {
    fontWeight: 800,
    cursor: "pointer",
    margin: 6,
    paddingTop: 9,
    marginLeft: 24,
    fontSize: 16,

    color: "#fff",
    fontWeight: 900,
  },
  button: {
    borderRadius: "15px",
    zIndex: 203,
    marginTop: 6,
    paddingLeft: 18,
    paddingRight: 18,
    fontSize: 14,
    fontWeight: 800,
    marginRight: 12,
    marginLeft: 12,
    color: "#fff",
    textTransform: "capitalize",
  },
}))

export default useNavbarStyles
